<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <vs-prompt title="Exportar a Excel" class="export-options" @cancle="clearFields" @accept="exportToExcel"
               accept-text="Exportar" cancel-text="Cancelar" @close="clearFields" :active.sync="activePrompt">
      <vs-input v-model="fileName" placeholder="Ingresa el nombre del archivo..." class="w-full"/>
      <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
      <div class="flex">
        <span class="mr-4">Auto ajustar columna:</span>
        <vs-switch v-model="cellAutoWidth">Auto ajustar columna</vs-switch>
      </div>
    </vs-prompt>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <!--Filter by date-->
      <div>
        <h6 class="mt-2">Rango de fechas</h6>
        <!--Dates-->
        <div class="vx-row mt-4">
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Desde*</label>
            <datepicker :language="es" label="Desde" v-model="startDate" id="startDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Hasta*</label>
            <datepicker :language="es" label="Gasta" v-model="endDate" id="endDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
        </div>
        <!--Clients-->
        <div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2">
              <h6 class="mt-5 mb-2">Selecciona cliente</h6>
              <v-select :options="clients" :clearable="true"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="client" class="mb-4 md:mb-0" name="client" id="client"/>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col mt-2">
        <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
      </div>
    </vx-card>

    <new :clients="clients" :products="products" @add="addDataSideBar" :isSidebarActive="showNewSidebar"
         @closeSidebar="showNewSidebar= false"/>

    <update :clients="clients" :products="products" @update="updateDateSideBar" :isSidebarActive="showUpdateSidebar"
            @closeSidebar="showUpdateSidebar= false"
            :data="sidebarData"/>

    <detail :data="sidebarData" :isSidebarActive="showDetailSidebar"
            @closeSidebar="showDetailSidebar= false"/>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="list">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <!--Export-->
              <vs-dropdown-item @click="activePrompt=true">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                               <span>Exportar (Formato para Excel)</span>
                            </span>
              </vs-dropdown-item>
              <!--Edit-->
              <vs-dropdown-item v-if="selected.status === 'hold'"
                                @click="sidebarData = selected; showUpdateSidebar = true">
                            <span class="flex items-center">
                              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Editar</span>
                            </span>
              </vs-dropdown-item>
              <!--Movements-->
              <vs-dropdown-item
                  v-if="selected.id"
                  @click="sidebarData = selected; showDetailSidebar = true">
                            <span class="flex items-center">
                              <feather-icon icon="EyeIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver movimientos (M)</span>
                            </span>
              </vs-dropdown-item>
              <!--Link-->
              <vs-dropdown-item
                  v-if="selected.id"
                  @click="openLink(selected.link)">
                            <span class="flex items-center">
                              <feather-icon icon="ExternalLinkIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver documento (O)</span>
                            </span>
              </vs-dropdown-item>
              <!--Clone-->
              <vs-dropdown-item
                  v-if="selected.id"
                  @click="openResendDialog(selected)">
                            <span class="flex items-center">
                              <feather-icon icon="CopyIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Clonar (C)</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <!-- Add new -->
          <div
              class="btn-add-new p-3 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="showNewSidebar= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar</span>
          </div>
        </div>

        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ list.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : list.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="client">Cliente</vs-th>
        <vs-th sort-key="sequence">Nro. Cotización</vs-th>
        <vs-th sort-key="total">Total</vs-th>
        <vs-th sort-key="status">Estado</vs-th>
        <vs-th sort-key="registrationDate">Fecha de registro</vs-th>
        <vs-th sort-key="registrationDate">Última Modificación</vs-th>
        <vs-th sort-key="user">Usuario</vs-th>
        <vs-th sort-key="actions">Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="font-medium truncate product-name">{{ tr.client.businessName }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.code }}</p>
          </vs-td>

          <vs-td>
            <p>
              {{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: tr.currency.code,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(tr.total)
              }}
            </p>
          </vs-td>

          <vs-td>
            <vs-chip :color="getStatusColor(tr.status)" class="product-order-status">
              {{ getStateText(tr.status) | title }}
            </vs-chip>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.createdAt | date(true) }}</p>
          </vs-td>

          <vs-td>
            <p v-if="tr.updatedAt" class="font-medium truncate">{{ tr.updatedAt | date(true) }}</p>
            <p v-else class="font-medium truncate">{{ tr.createdAt | date(true) }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.user.displayName }}</p>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon v-if="tr.status === 'hold'" class="mr-2" title="Editar" icon="EditIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="sidebarData = tr,showUpdateSidebar = true"/>
            <feather-icon @click="sidebarData= tr, showDetailSidebar = true" class="mr-2" title="Movimientos"
                          icon="EyeIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"/>
            <feather-icon @click="openLink(tr.link)" title="Ver documento" icon="ExternalLinkIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current" class="mr-2"/>

            <feather-icon @click="openResendDialog(tr)" title="Clonar" icon="CopyIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'

import { auth, db, FieldValue, storage, Timestamp } from '@/firebase/firebaseConfig'
import New from '@/views/quotes/New'
import Update from '@/views/quotes/Update'
import Detail from '@/views/quotes/Detail'

import { es } from 'vuejs-datepicker/dist/locale'
import openLink from '@/mixins/link'
import axios from 'axios'

const algoliasearch = require('algoliasearch')

const client = algoliasearch('5VESBBEHLC', '1e6b9aa2b3df2add6b40142346274dbe')
const index = client.initIndex('products')

const _ = require('lodash')
export default {
  name: 'QuotesList',
  components: {
    vSelect,
    New,
    Update,
    Detail,
    Datepicker
  },
  data () {
    return {
      selected: {},
      itemsPerPage: 10,
      isMounted: false,
      list: [],
      clients: [],
      products: [],
      initProgress: false,

      // Data Sidebar
      showUpdateSidebar: false,
      showNewSidebar: false,
      showDetailSidebar: false,
      sidebarData: {},
      // Export
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      activePrompt: false,
      // Filter
      startDate: null,
      endDate: null,
      es: es,
      client: null,
      evt: null,
      selectedQuote: null
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  mixins: [openLink],
  async created () {
    try {
      this.initProgress = true
      await this.loadQuotes()
      await this.loadProducts()
      await this.loadClients()
      // Set filter las 30 days
      this.startDate = new Date(new Date().setDate(new Date().getDate() - 30))
      this.endDate = new Date()
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true

    // Listen keyboard
    // this.evt = window.document
    // this.evt.addEventListener('keyup', this.listenKeyboard)
  },
  destroyed () {
    this.evt.removeEventListener('keyup', this.listenKeyboard)
  },
  methods: {
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        const list = _.cloneDeep(this.list)
        list.forEach((o) => {
          o.client = o.client.businessName
          o.currency = o.currency.value
          o.createdAt = this.$options.filters.date(o.createdAt, true)
          o.user = o.user.displayName
          o.state = this.getStateText(o.status)
        })
        const headerVal = ['client', 'code', 'currency', 'total', 'createdAt', 'user', 'state']
        const headerTitle = ['Client', 'Nro. Cotización', 'Moneda', 'Total', 'Fecha de registro', 'Usuario', 'Estado']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.clearFields()
      })
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },
    /**
     * Add data of sidebar
     * */
    addDataSideBar (o) {
      this.list.unshift(o)
    },
    /**
     * Update data of sidebar
     * */
    updateDateSideBar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Get status color
     * @param status
     * @returns {string}
     */
    getStatusColor (status) {
      if (status === 'hold') return 'warning'
      if (status === 'inProcess') return 'dark'
      if (status === 'rejected') return 'danger'
      if (status === 'invoiced') return 'success'
      return 'success'
    },
    /**
     * Get state text
     * @param value
     * @returns {string}
     */
    getStateText (value) {
      if (value === 'hold') return 'Generada'
      if (value === 'invoiced') return 'Facturada'
      if (value === 'inProcess') return 'En proceso'
      if (value === 'rejected') return 'Anulada'
    },
    /**
     *
     * @returns {Promise<void>}
     */
    async loadQuotes () {
      let querySnap = {}
      if (this.$acl.check('admin')) {
        querySnap = await db.collection('quotes')
            .orderBy('createdAt', 'desc')
            .limit(100).get()
      } else {
        querySnap = await db.collection('quotes')
            .where('user.uid', '==', auth.currentUser.uid)
            .orderBy('createdAt', 'desc')
            .limit(100).get()
      }

      querySnap.forEach((doc) => {
        let obj = {
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate(),
          updatedAt: doc.data().updatedAt ? doc.data().updatedAt.toDate() : null
        }
        this.list.push(obj)
      })
    },
    /**
     * Get clients of firestore
     * @returns {Promise<void>}
     */
    async loadClients () {
      this.clients = []
      const querySnap = await db.collection('clients').where('state', '==', true).orderBy('businessName', 'asc').get()
      querySnap.forEach((doc) => {
        let client = {
          id: doc.id,
          document: doc.data().document,
          typeDocument: doc.data().typeDocument,
          businessName: doc.data().businessName,
          label: doc.data().businessName + ' - ' + doc.data().document,
          address: doc.data().address,
          department: doc.data().department,
          province: doc.data().province,
          district: doc.data().district,
          ubigeo: doc.data().ubigeo,
          email: doc.data().email,
          retainer: doc.data().retainer ? doc.data().retainer : 'false',
          paymentMethod: doc.data().paymentMethod || null
        }
        this.clients.push(client)
      })
    },
    /**
     * Get products of algolia
     * @returns {Promise<void>}
     */
    async loadProducts () {
      // Search products in algolia, if search is empty list preview
      let response = await index.search('', {
        hitsPerPage: 20,
      })
      response.hits.forEach((p) => {
        this.products.push({
          ...p,
          id: p.objectID
        })
      })
      // End
    },
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    async resetFilters () {
      this.initProgress = true
      this.list = []
      this.startDate = null
      this.endDate = null
      this.client = null
      await this.loadQuotes()
      this.$refs.filterCard.removeRefreshAnimation()
      this.initProgress = false
    },
    /**
     * Filter by date
     */
    async filterByDate () {
      try {
        if (this.startDate && this.endDate) {
          this.initProgress = true
          this.list = []
          // Set start date
          this.startDate.setHours(0)
          this.startDate.setMinutes(0)
          // Set end date
          this.endDate.setHours(23)
          this.endDate.setMinutes(59)

          const starTimes = Timestamp.fromDate(this.startDate)
          const endTimes = Timestamp.fromDate(this.endDate)
          let querySnapshot = {}

          if (this.client) {
            if (this.$acl.check('admin')) {
              querySnapshot = await db.collection('quotes')
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes)
                  .where('client.id', '==', this.client.id).orderBy('createdAt', 'desc').get()
            } else {
              querySnapshot = await db.collection('quotes')
                  .where('user.uid', '==', auth.currentUser.uid)
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes)
                  .where('client.id', '==', this.client.id).orderBy('createdAt', 'desc').get()
            }
          } else {
            if (this.$acl.check('admin')) {
              querySnapshot = await db.collection('quotes')
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes).orderBy('createdAt', 'desc').get()
            } else {
              querySnapshot = await db.collection('quotes')
                  .where('user.uid', '==', auth.currentUser.uid)
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes).orderBy('createdAt', 'desc').get()
            }
          }

          querySnapshot.forEach((doc) => {
            let obj = {
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate(),
              updatedAt: doc.data().updatedAt ? doc.data().updatedAt.toDate() : null
            }
            this.list.push(obj)
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Listen keyboard
     */
    listenKeyboard (ev) {
      if (this.selected.id && !this.showDetailSidebar && !this.showUpdateSidebar && !this.showNewSidebar && !this.activePrompt) {
        if (ev.keyCode === 69) {  // Edit (E)
          if (this.selected.status === 'hold') {
            this.showUpdateSidebar = true
            this.sidebarData = this.selected
          }
        } else if (ev.keyCode === 77) { // Movements (M)
          this.showDetailSidebar = true
          this.sidebarData = this.selected
        } else if (ev.keyCode === 79) { // Open Link (O)
          this.openLink(this.selected.link)
        } else if (ev.keyCode === 67) {  // Edit (C)
          this.openResendDialog(this.selected)
        }
      }
    },
    /**
     * Send to Eprint
     * @param tr
     */
    openResendDialog (tr) {
      this.selectedQuote = tr
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmación',
        text: `¿Deseas clonar la cotización "${tr.code}"?`,
        accept: this.resend,
        cancel: '',
        parameters: [tr],
        acceptText: 'Clonar',
        cancelText: 'Cancelar'
      })
    },
    /**
     *
     */
    async resend () {
      const quote = this.selectedQuote
      try {
        this.initProgress = true
        // Get sequence
        const response = await axios.get('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/sequences-getSequenceForQuotes', {
          headers: {
            'Authorization': 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })
        const sequence = response.data.sequence
        // Duplicate
        let obj = {
          ...quote,
          state: true,
          status: 'hold',
          products: [
            ...quote.products
          ],
          user: {
            displayName: auth.currentUser.displayName,
            uid: auth.currentUser.uid
          },
          code: 'CT-' + sequence,
          link: ''
        }
        obj.products.forEach((p) => {
          p.guide = 0
          p.invoice = 0
          p.purchaseOrder = 0

          if (p.addedProducts) {
            p.addedProducts.forEach((p2 => {
              p2.guide = 0
              p2.invoice = 0
              p2.purchaseOrder = 0
            }))
          }
        })
        delete obj.id
        const doc = await db.collection('quotes').add({
          ...obj,
          createdAt: FieldValue.serverTimestamp()
        })
        obj.createdAt = new Date()
        obj.id = doc.id

        // Generate pdf in PDF Monkey
        const responsePdf = await this.generatePDF(obj)
        setTimeout(async () => {
          const link = await this.getPdfUrl(responsePdf.id, obj)
          await db.collection('quotes').doc(obj.id).update({
            pdfId: responsePdf.id,
            link
          })
          // End
          obj.link = link
          this.list.unshift(obj)
          window.open(obj.link, '_blank')
          this.initProgress = false
          this.$vs.notify({
            color: 'success',
            title: 'Cotización',
            text: 'Cotización duplicada correctamente.'
          })
        }, 7000)
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Generate PDF
     */
    async generatePDF (quote) {
      const products = []
      quote.products.forEach((p) => {
        const obj = {
          description: p.description,
          numberPart: p.numberPart,
          deliveryTerm: p.deliveryTerm,
          warranty: p.warranty,
          quantity: p.quantity
        }
        if (quote.igvType === 'No incluye IGV') {
          obj.price = parseFloat((p.price).toFixed(quote.roundingType))
          obj.total = parseFloat((p.quantity * p.price).toFixed(quote.roundingType))
        } else {
          obj.price = parseFloat((p.price * 1.18).toFixed(quote.roundingType))
          obj.total = parseFloat((p.quantity * obj.price).toFixed(quote.roundingType))
        }

        obj.price = quote.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
          currency: quote.currency.code,
          minimumFractionDigits: quote.roundingType,
          maximumFractionDigits: quote.roundingType,
        }).format(obj.price)

        obj.total = quote.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
          currency: quote.currency.code,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(obj.total)

        products.push(obj)
      })
      const response = await this.$http.post('https://api.pdfmonkey.io/api/v1/documents', {
        document: {
          // document_template_id: '35179281-1D0C-47BD-AC26-6476B2336E8D',
          document_template_id: '85AA3539-81AE-42E8-A033-4D76A1AEA4FB',
          payload: {
            'code': quote.code,
            'createdAt': `Lima ${new Date().getDate()} de ${this.getMonth(new Date().getMonth())} del ${new Date().getFullYear()}`,
            'businessName': quote.client.businessName,
            'businessDocument': quote.client.document,
            'businessAddress': quote.address.address + ', ' + quote.address.district + ', ' + quote.address.province + ', ' + quote.address.department,
            'businessContact': quote.contact.label,
            'paymentMethod': quote.paymentMethod.value,
            'offerValidity': quote.offerValidity,
            'currency': quote.currency.value,
            'userName': auth.currentUser.displayName,
            'userPhone': auth.currentUser.phoneNumber,
            'userEmail': auth.currentUser.email,
            'observation': quote.observation,
            'subTotal': quote.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
              currency: quote.currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(quote.subTotal),
            'igv': quote.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
              currency: quote.currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(quote.igv),
            'total': quote.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
              currency: quote.currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(quote.total),
            products
          },
          status: 'pending'
        }
      }, {
        headers: {
          // 'Authorization': 'Bearer iVtju3Mvy6c2gzhGWerf'
          'Authorization': 'Bearer xmxWbNo2oEWMAdaHxkQi'
        }
      })
      return response.data.document
    },
    /**
     * Get URL of monkey
     */
    async getPdfUrl (id, quote) {
      const response = await this.$http.get(`https://api.pdfmonkey.io/api/v1/documents/${id}`, {
        headers: {
          // 'Authorization': 'Bearer iVtju3Mvy6c2gzhGWerf'
          'Authorization': 'Bearer xmxWbNo2oEWMAdaHxkQi'
        }
      })
      // Save in firebase storage
      const timestamp = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate() + '-' + new Date().getHours() + '-' + new Date().getMinutes() + '-' + new Date().getSeconds()
      const docNameFinal = quote.code.toUpperCase() + '-' + timestamp + '.pdf'
      const responsePdf = await window.fetch(response.data.document.download_url)
      const storageRef = storage.ref(`quotes/${docNameFinal}`)
      const blob = await responsePdf.blob()
      const snapshot = await storageRef.put(blob)
      const url = await snapshot.ref.getDownloadURL()
      return url
    },
    /**
     * Get mont in string
     * @param month
     * @returns {string}
     */
    getMonth (month) {
      switch (month) {
        case 0:
          return 'Enero'
        case 1:
          return 'Febrero'
        case 2:
          return 'Marzo'
        case 3:
          return 'Abril'
        case 4:
          return 'Mayo'
        case 5:
          return 'Junio'
        case 6:
          return 'Julio'
        case 7:
          return 'Agosto'
        case 8:
          return 'Septiembre'
        case 9:
          return 'Octubre'
        case 10:
          return 'Noviembre'
        case 11:
          return 'Diciembre'
      }
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.product-name {
  max-width: 23rem;
}
</style>
